/* Dashboard.css */
.dashboard {
  height: 100vh;
  overflow-x: hidden;
}

.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  transition: all 0.3s;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.collapsed {
  margin-left: -250px;
}

.sidebar-header {
  background-color: #222;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #444;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.settings-button {
  color: white;
  background: none;
  border: none;
}

.sidebar-nav {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 0;
}

.sidebar-nav::-webkit-scrollbar {
  width: 6px;
}

.sidebar-nav::-webkit-scrollbar-track {
  background: #444;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 3px;
}

.sidebar-nav::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.sidebar-nav .nav-link {
  padding: 8px 15px;
  color: #fff;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.4;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.sidebar-nav .nav-link:hover {
  background-color: #444;
}

.main-content {
  flex: 1;
  margin-left: 250px;
  transition: all 0.3s;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content.sidebar-collapsed {
  margin-left: 0;
  width: 100%;
}

.main-header {
  position: sticky;
  top: 0;
  z-index: 999;
  background: white;
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.header-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  flex-grow: 1;
  margin-left: 1rem;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #dee2e6;
}

.admin-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.admin-button:hover {
  background-color: #0056b3;
}

.logout-button {
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #c82333;
}

.menu-toggle {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #dee2e6;
  font-size: 1.25rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.menu-toggle:hover {
  background-color: #e9ecef;
  color: #007bff;
  border-color: #007bff;
}

.menu-toggle:active {
  transform: scale(0.95);
}

.menu-toggle-icon {
  transition: transform 0.3s ease;
}

.menu-toggle-icon.collapsed {
  transform: rotate(180deg);
}

.power-bi-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
}

.power-bi-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sub-menu {
  padding-left: 15px;
}
.disabled-link {
  opacity: 0.5;
  cursor: not-allowed;
  color: #6c757d !important;
}

.no-data-indicator {
  font-size: 0.8em;
  font-style: italic;
  color: #999;
}
.sub-item {
  font-size: 0.95em;
  padding-left: 10px;
}
.welcome-text {
  color: #333;
  font-weight: 500;
}
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    transform: translateX(-100%);
  }

  .sidebar.collapsed {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0 !important;
  }

  .main-content.sidebar-collapsed {
    margin-left: 0;
  }

  .sidebar-nav {
    padding-bottom: 60px;
  }
}

/* Thêm các styles mới cho cấu trúc lồng nhau */
.sidebar-nav .nav-item .nav-link {
  padding-left: 1rem;
}

.sidebar-nav .nav-item .nav .nav-item .nav-link {
  /* padding-left: 2rem; */
}

.sidebar-nav .nav-item > button.nav-link {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: inherit;
}

.sidebar-nav .nav-item > button.nav-link:hover {
  background-color: rgba(0,0,0,0.1);
}

.folder-item {
  margin-left: 0;
}

.folder-item ul {
  margin-left: 1rem;
  border-left: 2px solid #444;
  padding-left: 0.5rem;
}

.nav-link {
  /* padding: 0.75rem 1rem; */
  transition: all 0.2s;
  border-radius: 4px;
  /* margin: 0.25rem 0.5rem; */
}

.nav-link span {
  display: inline-flex;
  align-items: center;
}

.folder-link {
  font-weight: 500;
  color: #e9ecef;
}

.disabled-link {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Style cho icon trong nav-link */
.sidebar-nav .nav-link span:first-child {
  flex-shrink: 0;
  margin-top: 2px;
}

/* Style cho text trong nav-link */
.sidebar-nav .nav-link span:last-child {
  flex-grow: 1;
}

/* Đảm bảo chiều rộng tối đa cho menu items */
.sidebar-nav .nav-item {
  max-width: 100%;
}